import axios from 'axios'
import Vue from 'vue'
import { Dialog } from 'vant';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import router from '../router'
const srevice =axios.create({
    // baseURL: import.meta.env.VITE_APP_BASE_API,
    // 超时
    timeout: 30000,
    // baseURL:`https://api.fuen.net/server/api/public/`,
    // baseURL:'https://api.hexchdoge.com/server/api/public/',
    headers: {
        //'content-type': 'application/x-www-form-urlencoded'
        // 'content-type': 'multipart/form-data'
         'content-type': 'application/json;charset=utf-8',
        // 'content-type': 'text/html;charset=utf-8',
        // 'content-type': 'text/xml'
       },
})
//请求拦截
// srevice.interceptors.request.use(
//     (config) => {
//         console.log(config);
//         if (!loadObj) {
//         }
//         return config;
//     },
//     (error) => {
//         Vue.nextTick(() => {
//             loadObj.close()
//         })
//         return Promise.reject(error)
//     }
// );
//响应拦截
srevice.interceptors.response.use(
    (response) => {
        // console.log(">>><<<",response);
        if (response.data.code === 1) {
            return response;
        } else if(response.data.code == 202){  
            Dialog({ message: response.data.msg,confirmButtonText:'OK' }).then(e=>{
                localStorage.removeItem('token')
                router.push("/");
            });
            return Promise.reject(response.data.msg);
        } else if(response.data.code == 0){  
            Dialog({ message: response.data.msg,confirmButtonText:'OK' });
            return Promise.reject(response.data.msg);
        } else{
            return Promise.reject(response.data.msg);
        }
    },
    (error) => {
        return Promise.reject(error)
    }
);

export default srevice;
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible/flexible'
import { Icon, Popup, Pagination, Field,NoticeBar,Button,Dialog,Uploader,Picker,Toast,Notify } from 'vant';
import store from './store'
import VueI18n from 'vue-i18n';
import VueClipboard from 'vue-clipboard2'
import { List,Cell,PullRefresh,NavBar  } from 'vant';
import VConsole from 'vconsole'
import request from "./utils/request";
import zh_lang from './lang/zh'
import en_lang from './lang/en'
import jp_lang from './lang/jp'
import hg_lang from './lang/hg'
import { numberToCurrencyNo } from '@/utils/utils'

// import * as socketApi from '@/utils/websocket'
// Vue.prototype.$socketApi = socketApi

import visibility from 'vue-visibility-change';
Vue.use(visibility);
Vue.prototype.$visibility = visibility


// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', numberToCurrencyNo)
Vue.prototype.$vconsole = VConsole;
Vue.prototype.$EventBus = new Vue()
const BASE_URL = "https://api.gainmer.vip" //接口api
Vue.prototype.$HOST_URL = "https://www.todaykd.xyz/" //域名
Vue.prototype.$BASE_URL = BASE_URL 
Vue.prototype.$store = store
request.defaults.baseURL = `${BASE_URL}/server/api/public/`;

// request.baseUrl
document.title="GAINMER"

Vue.use(List).use(Cell).use(PullRefresh).use(NavBar);
// 全局注册
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(NoticeBar);
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Notify);

Vue.use(VueClipboard)
Vue.use(Field);
Vue.config.productionTip = false


Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.getters.locale,
  messages: {
    "zh": {lang:zh_lang},
    "en": {lang:en_lang},
    "jp": {lang:jp_lang},
    "hg": {lang:hg_lang}
  },
  silentTranslationWarn: true, // 去除国际化警告
})

window.eventBus = new Vue();

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

const user = {
    state: {
        price: 0
    },
    mutations: {
        SET_PRICE: (state, price) => {
            console.log(888888,price)
            state.price = price
        }
    }
}
export default user;
export default {
    rule: "규칙",
    investment: "총투자액",
    mine: "내 거",
    Lotteryrecord: "정산기록",
    Gamerecord: "게임 기록",
    Trend: "경향",
    bravetroops: "비트코인",
    tiger: "라이트코인",
    leopard: "이더 리움",
    Numberofperiods: "일련번호",
    Lotterytime: "결제 시간",
    Lotteryresults: "정산 결과",
    Bet: "내기",
    Win: "승리",
    countdown: "카운트다운",
    previouspage: "이전 페이지",
    nextpage: "다음 페이지",
    Lastroundresults:	"지난 라운드 결과",
    details: "세부",
    Leopardodds: "이더리움 구매",
    tigerodds: "라이트코인 구매",
    Nodata: "데이터 없음",
    Relevantrules: "관련 규칙",
    return: "반품",
    Turntablegame: "계약거래",
    PetMarket: "펫마켓",
    foundation: "기반",
    Usercenter: "사용자 센터",
    ServiceOnline: "온라인 서비스",
    help: "도움말 설명",
    announcement: "발표하다",
    Language: "언어 전환",
    Whitepaper: "백지",
    zhiyarule: "약속비율에 따라 보상을 배분",
    Clickpledge: "약속하려면 클릭",
    Pledgerecord: "공약기록",
    Rewardrecord: "보상기록",
    Pledgetime: "공약시간",
    Pledgegoldcoins: "금화 서약",
    example: "예",
    tou: "깁스",
    jinbi: "금",
    huode: "결제 가능",
    goldnumbders: "금화의 개수",
    entergold: "금화 개수를 입력해주세요",
    usegold: "사용 가능한 금화",
    winninggold: "금화 획득 기대",
    Confirm: "확인하다",
    Cancel: "취소",
    information: "정보",
    successful: "구매 성공.",
    Pledgeofgold: "금화 공약",
    Pledgesucceeded: "공약 성공했어요. ",
    Notice: "발표",
    development: "이 기능은 개발 중입니다. ",
    rechargetitle: "골드코인 충전",
    rechargemsg: "물레놀이, 그리기, 다짐까지 금화 충전",
    Selectcurrency: "통화 선택",
    Rechargequantity: "충전 수량",
    Pleaseentertherechargequantity: "충전금액을 입력해주세요.",
    Getgold: "금화를 얻으세요",
    Confirmrecharge: "충전 확인",
    Rechargerecord: "충전기록",
    successfulrecharge: "재충전 성공을 축하합니다",
    Rechargeused: "충전기록",
    Accountbalance: "계정 잔액",
    Withdraw: "코인 출금",
    rotation: "교환",
    team: "내 팀",
    management: "에이전트 관리",
    Invite: "지금 초대하세요",
    game: "게임",
    Pledge: "약속",
    Inviteconnection: "연결 초대",
    Receiveraddress: "수취인 주소",
    entertherecipientaddress: "수취인의 주소를 입력해주세요",
    transferquantity: "교환 수량",
    Insufficientavailablegoldcoins: "사용 가능한 금화가 부족합니다",
    enterthequantityofmutualtransfer: "상호이체 수량을 입력해주세요",
    Reciprocalrecord: "상호이체기록",
    successfullytransferredout: "전출에 성공하셨습니다",
    Withdrawalquantity: "출금금액",
    entertheamountofwithdrawal: "출금할 코인 금액을 입력해주세요",
    Withdrawalrecord: "출금기록",
    Checkinginprogress: "검토 중이며 나중에 도착할 예정입니다",
    Adjustpersonnel: "조정 직원",
    all: "모두",
    entertheodds: "상대 배당률을 입력해주세요",
    odds: "확률 캐시백",
    Enteraddresssearch: "검색할 주소를 입력하세요",
    Languageswitching:		"언어 전환",
    Chinese: "중국 전통",
    English: "영어",
    Japanese: "일본어",
    Korean: "한글",
    Publishproperties: "콘텐츠 게시",
    entercontentpublishing: "게시할 콘텐츠를 입력하세요",
    Publishnow: "지금 게시",
    Informationreleasedsuccessfully: "정보가 성공적으로 공개되었습니다.",
    gamegold: "게임 금액",
    Gameresults: "게임 결과",
    Wintheprize: "승리",
    Gametime: "게임 시간",
    Win_msg: "축하해요",
    lose_msg: "보너스를 놓쳐서 아쉽네요",
    Totalassets: "총 자산",
    Totalprofitandloss: "총손익",
    Todayprofitandloss: "오늘의 손익",
    Recharge: "충전",
    Withdrawal: "철회하다",
    luckdraw: "운",
    type: "유형",
    time: "시간",
    Status: "상태",
    Funddetails: "세부정보",
    gameresults: "게임 결과",
    gamenum: "게임 금액",
    gametype: "게임 상태",
    myodds: "나의 확률",
    Copy: "클론",
    getWin: "축하해요",
    getLose: "보너스를 놓쳐서 아쉽네요",
    changesuccess: "성공적으로 수정되었습니다",
    TotalCommission: "총 수수료",
    GameCommission: "게임 커미션",
    PledgeCommission: "공약위원회",
    Commission: "수수료",
    wait: "나중에 다시 시도 해주십시오",
    Total: "완전 서약",
    teamZhiya: "팀",
    Exchange: "포인트를 교환해 더 많은 보상을 받으세요",
    again: "다시 담배를 피워라",
    Thanks: "참여해주셔서 감사합니다",
    LotteryRecord: "복권기록",
    DrawOnce: "빈도",
    ClickLuckyDraw: "그리려면 클릭하세요",
    noDraw: "무승부 횟수가 없습니다",
    sings: "페이지를 새로 고치고 다시 서명하세요",
    feedback: "피드백",
    Submitfeedback: "의견 제출",
    noMOre: "더 이상은 없어",
    submit: "제출하다",
    PleaseProvideFeedback: "의견을 입력해주세요",
    loading: "로드 중",
    ReleaseToRefresh: "릴리스 새로 고침",
    PullToRefresh: "새로고침하려면 아래로 당기세요",
    submitSuccess: "성공적으로 제출되었습니다",
    content: "콘텐츠",
    reply: "회신하다",
    反饋內容: "피드백 내용",
    反饋類型: "피드백 유형",
    上傳圖片: "이미지 업로드",
    maxUploadSize: "파일 크기는 1M를 초과할 수 없습니다",
    反饋紀錄: "피드백 기록",
    反饋內容: "피드백 내용",
    未回覆: "답변 없음",
    已回覆: "답장",
    暫無記錄: "기록 없음",
    合約交易: "계약 거래",
    當前擁有寵物: "현재 애완동물을 키우고 있습니다",
    兑换: "교환",
    收益記錄: "소득 기록",
    購買記錄: "구매 내역",
    收益: "소득",
    幣種: "통화",
    時間: "시간",
    消耗: "소비",
    最大: "최고",
    获得: "얻다",
    预估值: "추정 값",
    金幣: "금",
    兌換為金幣: "금화로 교환",
    兌換紀錄: "교환기록",
    返還紀錄: "복귀기록",
    名稱: "이름",
    數量: "수량",
    基金總額: "총 기금",
    已返基金: "펀드 반환",
    待返基金: "반환금",
    基金會說明: "재단 설명",
    我的直推: "직접 추천",
    邀請好友: "친구를 초대",
    複製鏈接: "링크 복사",
    團隊人數: "팀 규모",
    直推人數: "직접푸시번호",
    我的傭金: "나의 커미션",
    傭金明細: "커미션 내역",
    幣種類型: "통화 유형",
    帳戶明細: "계정 세부정보",
    Contract: "계약",
    Pets: "애완 동물",
    复制成功: "복사 성공",
    邀請碼: "초대 코드",
    委託持倉: "위임받는 자리",
    歷史訂單: "과거 주문",
    合約簡介: "계약서 소개",
    簡介: "소개",
    排名: "순위",
    發行時間: "게시 시간",
    發行總量: "총 발행량",
    流通總量: "전체 순환",
    總市值: "전체 시가총액",
    流通市值: "유통시장가치",
    歷史最高價: "역사상 최고가",
    歷史最低價: "역대 최저가",
    買跌: "다운 구매",
    買漲: "사세요",
    實時價格: "실시간 가격",
    委託類型: "위임 유형",
    委託時間: "위임 시간",
    委託手數: "위탁 로트 규모",
    請輸入手數: "로트 사이즈를 입력해주세요",
    手: "손",
    分鐘: "분",
    確定下單: "주문 확인",
    警告: "경고하다",
    警告提示: "사용자 등록을 하지 않으셨습니다. 초대등록을 하셔야 합니다. 담당자에게 문의하시기 바랍니다.",
    單價: "단가",
    預計收益: "예상 수입",
    寵物詳情: "애완동물 세부정보",
    寵物介紹: "애완동물 소개",
    購買: "구입하다",
    買入數量: "수량 구매",
    合計: "총",
    總額: "일시금",
    合約名稱: "계약명",
    手數: "로트 크기",
    槓桿: "지렛대",
    盈虧: "이익과 손실",
    开放日返还: "오픈 데이 리턴",
    常规返还: "정기반납",
    一鍵平倉: "클릭 한 번으로 포지션 닫기",
    平倉: "포지션 닫기",
    持倉類型: "직위 유형",
    方向: "방향",
    手續費: "수수료",
    開倉時間: "개장시간",
    開倉價格: "시가",
    當前價格: "현재 가격",
    委託手數: "위탁 로트 규모",
    預計盈虧: "추정손익",
    買漲: "사세요",
    平倉時間: "닫는 시간",
    操作類型: "작업 유형",
    系统平仓: "시스템 종료",
    用户平仓: "사용자 청산 포지션",
    待定: "미정",
    結算價格: "결제 가격",
    餘額: "균형",
    溫馨提示: "친절한 팁",
    兌換比例: "환율",
    本期開放日為: "이번 호의 개봉일은",
    請選擇反饋類型: "피드백 유형을 선택하세요",
    請輸入反饋內容: "피드백 내용을 입력해주세요",
    兌換金額: "교환금액",
    積分數量: "포인트 금액",
    錢包選擇: "지갑 선택",
    連接錢包: "커넥트 월렛",
    止損: "멈추다",
    止盈: "이익실현",
    设置: "설립하다",
    登陸: "착륙",
    登陸成功: "착륙 성공",
    地址: "주소",
    錢包地址: "지갑 주소",
    密碼: "비밀번호",
    修改密碼: "비밀번호 변경",
    舊密碼: "이전 비밀번호",
    新密碼: "새 비밀번호",
    请输入新密碼: "새 비밀번호를 입력해 주세요.",
    请再次输入密碼: "비밀번호를 다시 입력해 주세요",
    兩次密碼不一致: "암호가 두 번 일치하지 않습니다.",
    模擬綁定: "바인딩 시뮬레이션",
    綁定帳號: "계정 연동",
    執行比例: "실행 비율",
    解除綁定: "언바인딩",
    金額: "분량",
    退出: "사임하다",
    綁定啟動: "바인딩 시작",
    萬: "만",
    億: "억",
    h最高價: "시간 최고가",
    h最低價: "시간 최저가",
    h成交量: "시간 거래량",
    h成交額: "시간 회전율",
    帳號不能為空: "계정은 비어있을 수 없습니다",
    今日看點: "오늘의 하이라이트",
    歷史紀錄: "역사 기록",
    Fee: "단일 출금 수수료"
}

/**
 * 
 * @千位符
 * @用法全局 | numberToCurrency
 */

export function numberToCurrencyNo(value) {
    if (!value) return '0.00'
    value = Math.floor(Number(value) * 100)/100
    // 获取整数部分
    const intPart = Math.trunc(value)
    // 整数部分处理，增加,
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    // 预定义小数部分
    let floatPart = ''
    // 将数值截取为小数部分和整数部分
    const valueArray = value.toString().split('.')
    if (valueArray.length === 2) { // 有小数部分
      floatPart = valueArray[1].toString() // 取得小数部分
      return intPartFormat + '.' + floatPart
    }
    return intPartFormat + floatPart
  }
  

/**
 * @desc 防抖函数
 * @param fn 目标函数
 * @param wait 延迟执行毫秒数
*/
export function debounce (fn, wait = 500) {
  let timer=null
  return function () {
    clearTimeout(timer)
    timer=setTimeout(() => {

      fn.apply(this,arguments)
    }, wait);

  }
};
/**
 * @desc 节流函数
 * @param fn 目标函数
 * @param wait 延迟执行毫秒数
*/
export function throttle (fn, wait = 5500) {
  let last = 0;
  return function () {
    let now = Date.now();
    if (now - last > wait) {
      fn.apply(this, arguments);
      last = now;
    };
  };
};
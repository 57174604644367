<template>
  <div id="app">
    <!-- <MyTop v-if="showTop" /> -->
    <router-view  v-if="reload"/>
    <van-popup class="language1" v-model="window" :close-on-click-overlay="false" :closeable="false">
      <div class="title">
        <div class="left"></div>
        <div class="center">{{ $t("lang.警告") }}</div>
        <div
          class="right"
          @click="
            window = false;
          "
        >
          <!-- <img src="@/assets/index_logo/guanbi@2x.png" alt="" /> -->
        </div>
      </div>
      <div class="message">
        <div class="text">
          <span>{{ $t("lang.警告提示") }} </span>
        </div>
      </div>
      <!-- <div class="bottom_btn">
        <div
          class="button"
          @click="
            window = false;
          "
        >
          {{ $t("lang.Confirm") }}
        </div>
      </div> -->
    </van-popup>
  </div>
</template>

<script>
import { $getInfo } from "@/api/index.js";
import MyTop from "./components/mytop.vue";
import axios from "axios";
// import Web3 from "web3";
// import { ethers } from "ethers";

export default {
  name: "App",
  components: {
    MyTop,
  },
  data() {
    return {
      window:false,
      reload:true,
      showTop:false
    };
  },
  watch:{
    $route:function(val){
      this.showTop = !(val.meta && val.meta.noLogin)
    }
  },
  mounted(){
		// new this.$vconsole();
    // this.getUserInfo()
    const _this = this
    this.$EventBus.$on("selected", (data) => {
      _this.reload = false
      setTimeout(() => {
        _this.reload = true
      }, 200);
      
    });
  },
  // created() {
  //   // this.aa()
  //   this.v();
  // },
  methods: {
    // async v() {
    //   const provider = new ethers.providers.Web3Provider(window.ethereum);
    //   await provider.send("eth_requestAccounts", []);
    //   const signer = provider.getSigner();
    //   signer.getAddress().then((res) => {
    //     console.log(res);
    //     localStorage.setItem("user_add", res);
    //   });
    //   console.log(signer, 555);
    // },
    async getUserInfo(){
      // const res = await $getInfo({
      //   uid: localStorage.user_add,
      // })
      // if(!res.data.return){//未注册
      //   // this.window = true
      // }

    },
  },
};
</script>

<style>
* {
  font-family: PingFangSC-Semibold, PingFang SC;
}
body {
  margin: 0;
  background: #1B1B25;
}
input {
  padding: 0.25rem;
}
.van-field__control {
  color: #d8d2e4 !important;
}
.van-pagination__item {
  margin-top: .9375rem;
  background-color: #3E495B;
  color: #fff;
}
.van-pagination__item--active {
  background-color: #40c1cf;
}
.van-pagination__item--active:active{
  background-color: #40c1cf;
}
.van-nav-bar .van-nav-bar__title{
  color: #FFFFFE;
}

.nothing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 80%;
}
.nothing .img{
  background: #242734;
  border-radius: 50%;
}
.nothing .txt{
  font-size: 13px;
  color: #959AAF;
  line-height: 21px;
  margin-top: 11px;
}

.van-nav-bar .van-nav-bar__arrow {
  font-size: 22px;
}
.van-nav-bar .van-nav-bar__content .van-icon {
  color: #fff;
}
.van-nav-bar .van-nav-bar__left {
  margin-left: 0;
  padding-left: 10px;
}
.language1 {
  width: 8.9688rem;
  height: 6.2969rem;
  border-radius: 0.25rem;
  border: none;
  background-color: #222832;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #242636;
    height: 1.375rem;
    .left {
      width: 0.6875rem;
    }
    .center {
      width: 2.25rem;
      text-align: center;
      color: #d8d2e4;
      font-size: 18px;
    }
    .right {
      width: 0.6875rem;
      img {
        width: 0.4063rem;
        height: 0.4375rem;
      }
    }
  }
  .message {
    padding: 0 0.25rem;
    text-align: center;
    margin-top: 1.25rem;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
    .text {
      margin-top: 0.625rem;
      font-size: 15px;
      color: #d8d2e4;
      span:nth-of-type(2) {
        color: #21c7d4;
      }
    }
  }
  .bottom_btn {
    margin: 1rem 0.25rem 0 0.25rem;
    border-radius: 0.25rem;
    .button {
      width: 100%;
      height: 1.375rem;
      border-radius: 0.1563rem;
      background: #21c7d4;
      color: #ffffff;
      text-align: center;
      line-height: 1.375rem;
      font-size: 15px;
    }
  }
}
</style>

const lang = {
    state: {
        locale: "en"
    },
    mutations: {
        SET_LANG: (state, lang) => {
            state.locale = lang;
        }
    }
}
export default lang;
export default {
    rule: "規則",
    investment: "總投資",
    mine: "我的",
    Lotteryrecord: "結算記錄 ",
    Gamerecord: "遊戲記錄 ",
    Trend: "走勢",
    bravetroops: "比特幣",
    tiger: "萊特幣",
    leopard: "以太坊",
    Numberofperiods: "序號",
    Lotterytime: "結算時間",
    Lotteryresults: "結算結果",
    Bet: "投注",
    Win: "中獎",
    countdown: "倒數計時",
    previouspage: "上一頁",
    nextpage: "下一頁",
    Lastroundresults:	"上一輪結果",
    details: "詳情",
    Leopardodds: "以太坊购买",
    tigerodds: "萊特幣购买",
    Nodata: "暫無數據",
    Relevantrules: "相關規則",
    return: "返回",
    Turntablegame: "合約交易",
    PetMarket: "寵物市場",
    foundation: "基金會",
    Usercenter: "用戶中心",
    ServiceOnline: "在線客服",
    help: "幫助說明",
    announcement: "發佈公告",
    Language: "語言切換",
    Whitepaper: "白皮書",
    zhiyarule: "按照質押比例分配獎勵",
    Clickpledge: "點擊質押",
    Pledgerecord: "質押記錄",
    Rewardrecord: "獎勵記錄",
    Pledgetime: "質押時間",
    Pledgegoldcoins: "質押金幣",
    example: "例",
    tou: "投",
    jinbi: "金幣",
    huode: "结算可得",
    goldnumbders: "金幣數量",
    entergold: "請輸入金幣的數量",
    usegold: "可用金幣",
    winninggold: "預計獲得金幣",
    Confirm: "確認",
    Cancel: "取消",
    information: "資訊",
    successful: "購買成功.",
    Pledgeofgold: "金幣質押",
    Pledgesucceeded: "質押成功。",
    Notice: "公告",
    development: "該功能開發中。",
    rechargetitle: "金幣充值",
    rechargemsg: "充值金幣可玩轉盤、抽獎、質押",
    Selectcurrency: "選擇幣種",
    Rechargequantity: "充值數量",
    Pleaseentertherechargequantity: "請輸入充值的數量",
    Getgold: "獲得金幣" ,
    Confirmrecharge: "確認充值",
    Rechargerecord: "充值記錄",
    successfulrecharge: "恭喜你成功充值",
    Rechargeused: "充值記錄",
    Accountbalance: "賬戶餘額",
    Withdraw: "提幣",
    rotation: "互轉",
    team: "我的團隊",
    management: "代理管理",
    Invite: "立即邀請",
    game: "遊戲",
    Pledge: "質押",
    Inviteconnection: "邀请连接",
    Receiveraddress: "接收方地址",
    entertherecipientaddress: "請輸入接收方地址",
    transferquantity: "互轉數量",
    Insufficientavailablegoldcoins: "可用金幣不足",
    enterthequantityofmutualtransfer: "請輸入互轉的數量",
    Reciprocalrecord: "互轉記錄",
    successfullytransferredout: "您成功轉出",
    Withdrawalquantity: "提幣數量",
    entertheamountofwithdrawal: "請輸入提幣的數量",
    Withdrawalrecord: "提幣記錄",
    Checkinginprogress: "正在審核，稍後到賬",
    Adjustpersonnel: "調整人員",
    all: "全部",
    entertheodds: "請輸入對方賠率",
    odds: "赔率返现",
    Enteraddresssearch: "輸入地址蒐索",
    Languageswitching:		"語言切換",
    Chinese: "繁體中文",
    English: "English",
    Japanese: "日本語",
    Korean: "한글",
    Publishproperties: "發佈內容",
    entercontentpublishing: "請輸入內容發佈",
    Publishnow: "立即發佈",
    Informationreleasedsuccessfully: "資訊發佈成功。",
    gamegold: "遊戲金額",
    Gameresults: "遊戲結果",
    Wintheprize: "中獎",
    Gametime: "遊戲時間",
    Win_msg: "恭喜你",
    lose_msg: "很遺憾您與獎金擦肩而過",
    Totalassets: "總資產",
    Totalprofitandloss: "總盈虧",
    Todayprofitandloss: "今日盈虧",
    Recharge: "充值",
    Withdrawal: "提現",
    luckdraw: "抽獎",
    type: "類型",
    time: "時間",
    Status: "狀態",
    Funddetails: "詳情",
    gameresults: "遊戲結果",
    gamenum: "遊戲金額",
    gametype: "遊戲狀態",
    myodds: "我的賠率",
    Copy: "複製",
    getWin: "恭喜你",
    getLose: "很遺憾您與獎金擦肩而過",
    changesuccess: "修改成功",
    TotalCommission: "總傭金",
    GameCommission: "遊戲傭金",
    PledgeCommission: "質押傭金",
    Commission: "傭金",
    wait: "請稍後再試",
    Total: "總質押",
    teamZhiya: "團隊",
    Exchange: "用積分換取更多獎勵",
    again: "再抽一次",
    Thanks: "謝謝參與",
    LotteryRecord: "抽奖記錄",
    DrawOnce: "次數",
    ClickLuckyDraw: "點擊抽獎",
    noDraw: "你沒有抽獎次數",
    sings: "請刷新頁面並重新簽名",
    feedback: "意見反饋",
    Submitfeedback: "提交反饋",
    noMOre: "沒有更多了",
    submit: "提交",
    PleaseProvideFeedback: "請输入意见反饋",
    loading: "加载中",
    ReleaseToRefresh: "釋放刷新",
    PullToRefresh: "下拉刷新",
    submitSuccess: "提交成功",
    content: "内容",
    reply: "回复",
    反饋內容: "反饋內容",
    反饋類型: "反饋類型",
    上傳圖片: "上傳圖片",
    maxUploadSize: "文件大小不能超过 1M",
    反饋紀錄: "反饋紀錄",
    反饋內容: "反饋內容",
    未回覆: "未回覆",
    已回覆: "已回覆",
    暫無記錄: "暫無記錄",
    合約交易: "合約交易",
    當前擁有寵物: "當前擁有寵物",
    兑换: "兑换",
    收益記錄: "收益記錄",
    購買記錄: "購買記錄",
    收益: "收益",
    幣種: "幣種",
    時間: "時間",
    消耗: "消耗",
    最大: "最大",
    获得: "获得",
    预估值: "预估值",
    金幣: "金幣",
    兌換為金幣: "兌換為金幣",
    兌換紀錄: "兌換紀錄",
    返還紀錄: "返還紀錄",
    名稱: "名稱",
    數量: "數量",
    基金總額: "基金總額",
    已返基金: "已返基金",
    待返基金: "待返基金",
    基金會說明: "基金會說明",
    我的直推: "我的直推",
    邀請好友: "邀請好友",
    複製鏈接: "複製鏈接",
    團隊人數: "團隊人數",
    直推人數: "直推人數",
    我的傭金: "我的傭金",
    傭金明細: "傭金明細",
    幣種類型: "幣種類型",
    帳戶明細: "帳戶明細",
    Contract: "合約",
    Pets: "寵物",
    复制成功: "复制成功",
    邀請碼: "邀請碼",
    委託持倉: "委託持倉",
    歷史訂單: "歷史訂單",
    合約簡介: "合約簡介",
    簡介: "簡介",
    排名: "排名",
    發行時間: "發行時間",
    發行總量: "發行總量",
    流通總量: "流通總量",
    總市值: "總市值",
    流通市值: "流通市值",
    歷史最高價: "歷史最高價",
    歷史最低價: "歷史最低價",
    買跌: "買跌",
    買漲: "買漲",
    實時價格: "實時價格",
    委託類型: "委託類型",
    委託時間: "委託時間",
    委託手數: "委託手數",
    請輸入手數: "請輸入手數",
    手: "手",
    分鐘: "分鐘",
    確定下單: "確定下單",
    警告: "警告",
    警告提示: "您还未注册用户，需通过邀请注册，请联系负责人",
    單價: "單價",
    預計收益: "預計收益",
    寵物詳情: "寵物詳情",
    寵物介紹: "寵物介紹",
    購買: "購買",
    買入數量: "買入數量",
    合計: "合計",
    總額: "總額",
    合約名稱: "合約名稱",
    手數: "手數",
    槓桿: "槓桿",
    盈虧: "盈虧",
    开放日返还: "开放日返还",
    常规返还: "常规返还",
    一鍵平倉: "一鍵平倉",
    平倉: "平倉",
    持倉類型: "持倉類型",
    方向: "方向",
    手續費: "手續費",
    開倉時間: "開倉時間",
    開倉價格: "開倉價格",
    當前價格: "當前價格",
    委託手數: "委託手數",
    預計盈虧: "盈虧",
    買漲: "買漲",
    平倉時間: "平倉時間",
    操作類型: "操作類型",
    系统平仓: "系统平仓",
    用户平仓: "用户平仓",
    待定: "待定",
    結算價格: "結算價格",
    餘額: "餘額",
    溫馨提示: "溫馨提示",
    兌換比例: "兌換比例",
    本期開放日為: "本期開放日為",
    請選擇反饋類型: "請選擇反饋類型",
    請輸入反饋內容: "請輸入反饋內容",
    兌換金額: "兌換金額",
    積分數量: "積分數量",
    錢包選擇: "錢包選擇",
    連接錢包: "連接錢包",
    止損: "止損",
    止盈: "止盈",
    设置: "设置",
    登陸: "登陸",
    登陸成功: "登陸成功",
    地址: "地址",
    錢包地址: "錢包地址",
    密碼: "密碼",
    修改密碼: "修改密碼",
    舊密碼: "舊密碼",
    新密碼: "新密碼",
    请输入新密碼: "請輸入新密碼",
    请再次输入密碼: "请再次输入密碼",
    兩次密碼不一致: "兩次密碼不一致",
    模擬綁定: "模擬綁定",
    綁定帳號: "綁定帳號",
    執行比例: "執行比例",
    解除綁定: "解除綁定",
    綁定啟動: "綁定啟動",
    金額: "金額",
    退出: "退出",
    萬: "萬",
    億: "億",
    h最高價: "h最高價",
    h最低價: "h最低價",
    h成交量: "h成交量",
    h成交額: "h成交額",
    帳號不能為空: "帳號不能為空",
    今日看點: "今日看點",
    歷史紀錄: "歷史紀錄",
    Fee: "單筆提現手續費"
}
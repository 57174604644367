export default {
    rule: "ルール",
    investment: "総投資額",
    mine: "私の",
    Lotteryrecord: "決済記録",
    Gamerecord: "棋譜",
    Trend: "傾向",
    bravetroops: "ビットコイン",
    tiger: "ライトコイン",
    leopard: "イーサリアム",
    Numberofperiods: "シリアルナンバー",
    Lotterytime: "決済時間",
    Lotteryresults: "決済結果",
    Bet: "ベット",
    Win: "勝つ",
    countdown: "秒読み",
    previouspage: "前のページ",
    nextpage: "次のページ",
    Lastroundresults:	"最終ラウンドの結果",
    details: "詳細",
    Leopardodds: "イーサリアムを購入",
    tigerodds: "ライトコインを購入",
    Nodata: "データなし",
    Relevantrules: "関連するルール",
    return: "戻る",
    Turntablegame: "契約取引",
    PetMarket: "ペットマーケット",
    foundation: "財団",
    Usercenter: "ユーザーセンター",
    ServiceOnline: "オンラインサービス",
    help: "ヘルプの説明",
    announcement: "発表する",
    Language: "言語切り替え",
    Whitepaper: "白書",
    zhiyarule: "誓約率に応じて報酬を分配",
    Clickpledge: "クリックして誓約する",
    Pledgerecord: "誓約書",
    Rewardrecord: "報酬記録",
    Pledgetime: "約束の時間",
    Pledgegoldcoins: "金貨の誓約",
    example: "例",
    tou: "キャスト",
    jinbi: "金",
    huode: "決済可能",
    goldnumbders: "金貨の枚数",
    entergold: "金貨の枚数を入力してください",
    usegold: "利用可能な金貨",
    winninggold: "金貨の獲得に期待",
    Confirm: "確認する",
    Cancel: "キャンセル",
    information: "情報",
    successful: "購入が成功しました。",
    Pledgeofgold: "金貨の誓約書",
    Pledgesucceeded: "誓約は成功しました。 ",
    Notice: "発表",
    development: "この機能は開発中です。 ",
    rechargetitle: "ゴールドコインチャージ",
    rechargemsg: "金貨をチャージしてホイールをプレイし、ドローし、誓約します",
    Selectcurrency: "通貨を選択",
    Rechargequantity: "リチャージ量",
    Pleaseentertherechargequantity: "チャージ金額を入力してください",
    Getgold: "金貨を手に入れよう",
    Confirmrecharge: "充電を確認",
    Rechargerecord: "リチャージ記録",
    successfulrecharge: "リチャージ成功おめでとうございます",
    Rechargeused: "リチャージ記録",
    Accountbalance: "勘定残高",
    Withdraw: "コインを引き出す",
    rotation: "交換",
    team: "私のチーム",
    management: "エージェント管理",
    Invite: "今すぐ招待してください",
    game: "ゲーム",
    Pledge: "誓約書",
    Inviteconnection: "接続を招待",
    Receiveraddress: "受信者アドレス",
    entertherecipientaddress: "受取人の住所を入力してください",
    transferquantity: "交換数量",
    Insufficientavailablegoldcoins: "利用可能な金貨が足りません",
    enterthequantityofmutualtransfer: "相互送金の数量を入力してください",
    Reciprocalrecord: "相互転送レコード",
    successfullytransferredout: "無事に転校できました",
    Withdrawalquantity: "出金額",
    entertheamountofwithdrawal: "引き出すコインの量を入力してください",
    Withdrawalrecord: "出金記録",
    Checkinginprogress: "検討中のため、後日到着します",
    Adjustpersonnel: "調整要員",
    all: "全て",
    entertheodds: "相手のオッズを入力してください",
    odds: "オッズキャッシュバック",
    Enteraddresssearch: "住所を入力して検索",
    Languageswitching:		"言語スイッチ",
    Chinese: "繁体字中国語",
    English: "英語",
    Japanese: "日本語",
    Korean: "韓国語",
    Publishproperties: "コンテンツの公開",
    entercontentpublishing: "公開するコンテンツを入力してください",
    Publishnow: "今すぐ公開",
    Informationreleasedsuccessfully: "無事に情報公開されました。",
    gamegold: "ゲーム金額",
    Gameresults: "試合結果",
    Wintheprize: "勝つ",
    Gametime: "ゲームの時間",
    Win_msg: "おめでとう",
    lose_msg: "ボーナスを逃したのは残念です",
    Totalassets: "総資産",
    Totalprofitandloss: "総損益",
    Todayprofitandloss: "今日の損益",
    Recharge: "補充",
    Withdrawal: "撤回する",
    luckdraw: "宝くじ",
    type: "タイプ",
    time: "時間",
    Status: "州",
    Funddetails: "詳細",
    gameresults: "試合結果",
    gamenum: "ゲーム金額",
    gametype: "ゲームステータス",
    myodds: "私のオッズ",
    Copy: "クローン",
    getWin: "おめでとう",
    getLose: "ボーナスを逃したのは残念です",
    changesuccess: "正常に変更されました",
    TotalCommission: "合計コミッション",
    GameCommission: "ゲームコミッション",
    PledgeCommission: "誓約委員会",
    Commission: "手数料",
    wait: "後でもう一度お試しください",
    Total: "誓約総額",
    teamZhiya: "チーム",
    Exchange: "ポイントをより多くの特典と交換",
    again: "もう一度吸ってください",
    Thanks: "ご参加いただきありがとうございます",
    LotteryRecord: "宝くじ記録",
    DrawOnce: "頻度",
    ClickLuckyDraw: "クリックして描画",
    noDraw: "ドローの数がありません",
    sings: "ページを更新して再署名してください",
    feedback: "フィードバック",
    Submitfeedback: "フィードバックを送信",
    noMOre: "もうない",
    submit: "提出する",
    PleaseProvideFeedback: "フィードバックを入力してください",
    loading: "読み込み中",
    ReleaseToRefresh: "リフレッシュをリリース",
    PullToRefresh: "プルダウンして更新",
    submitSuccess: "正常に送信されました",
    content: "コンテンツ",
    reply: "返事",
    反饋內容: "フィードバックの内容",
    反饋類型: "フィードバックの種類",
    上傳圖片: "画像をアップロード",
    maxUploadSize: "ファイルサイズは1Mを超えることはできません",
    反饋紀錄: "フィードバック記録",
    反饋內容: "フィードバックの内容",
    未回覆: "応答なし",
    已回覆: "答えた",
    暫無記錄: "記録なし",
    合約交易: "契約取引",
    當前擁有寵物: "現在ペットを飼っています",
    兑换: "交換",
    收益記錄: "収入記録",
    購買記錄: "購入履歴",
    收益: "所得",
    幣種: "通貨",
    時間: "時間",
    消耗: "消費",
    最大: "最大",
    获得: "得る",
    预估值: "推定値",
    金幣: "金",
    兌換為金幣: "金貨との交換",
    兌換紀錄: "交換記録",
    返還紀錄: "返品記録",
    名稱: "名前",
    數量: "量",
    基金總額: "総資金",
    已返基金: "資金が返還されました",
    待返基金: "返還される資金",
    基金會說明: "基礎の説明",
    我的直推: "私の直接の推薦",
    邀請好友: "友達を招待",
    複製鏈接: "リンクをコピーする",
    團隊人數: "チームサイズ",
    直推人數: "ダイレクトプッシュ番号",
    我的傭金: "私の任務",
    傭金明細: "コミッションの内訳",
    幣種類型: "通貨の種類",
    帳戶明細: "アカウント詳細",
    Contract: "契約",
    Pets: "ペット",
    复制成功: "コピーに成功しました",
    邀請碼: "招待コード",
    委託持倉: "委託されたポジション",
    歷史訂單: "注文履歴",
    合約簡介: "契約のご紹介",
    簡介: "導入",
    排名: "ランキング",
    發行時間: "公開時間",
    發行總量: "発行総額",
    流通總量: "総発行部数",
    總市值: "時価総額の合計",
    流通市值: "流通市場価値",
    歷史最高價: "史上最高値",
    歷史最低價: "史上最低価格",
    買跌: "買い取り",
    買漲: "買い占めます",
    實時價格: "リアルタイム価格",
    委託類型: "委任タイプ",
    委託時間: "委任時間",
    委託手數: "委託ロットサイズ",
    請輸入手數: "ロットサイズを入力してください",
    手: "手",
    分鐘: "分",
    確定下單: "注文を確認する",
    警告: "警告",
    警告提示: "ユーザー登録がされていません。招待登録が必要です。担当者にご連絡ください。",
    單價: "単価",
    預計收益: "推定収益額",
    寵物詳情: "ペットの詳細",
    寵物介紹: "ペット紹介",
    購買: "買う",
    買入數量: "数量を購入",
    合計: "合計",
    總額: "一時金",
    合約名稱: "契約名",
    手數: "ロットサイズ",
    槓桿: "レバー",
    盈虧: "利益と損失",
    开放日返还: "オープンデイリターン",
    常规返还: "定期的な返品",
    一鍵平倉: "ワンクリックでポジションを閉じる",
    平倉: "クローズポジション",
    持倉類型: "ポジションタイプ",
    方向: "方向",
    手續費: "手数料",
    開倉時間: "開始時間",
    開倉價格: "始値",
    當前價格: "現在の価格",
    委託手數: "委託ロットサイズ",
    預計盈虧: "損益",
    買漲: "買い占めます",
    平倉時間: "閉店時間",
    操作類型: "操作タイプ",
    系统平仓: "システムを終了します",
    用户平仓: "ユーザーのクローズ位置",
    待定: "決断される",
    結算價格: "決済価格",
    餘額: "バランス",
    溫馨提示: "親切なヒント",
    兌換比例: "為替レート",
    本期開放日為: "この号の創刊日は",
    請選擇反饋類型: "フィードバックの種類を選択してください",
    請輸入反饋內容: "フィードバックの内容を入力してください",
    兌換金額: "交換金額",
    積分數量: "ポイント数",
    錢包選擇: "ウォレットの選択",
    連接錢包: "ウォレットを接続する",
    止損: "止める",
    止盈: "利益を得る",
    设置: "並べる",
    登陸: "上陸",
    登陸成功: "着陸成功",
    地址: "住所",
    錢包地址: "ウォレットアドレス",
    密碼: "パスワード",
    修改密碼: "パスワードの変更",
    舊密碼: "古いパスワード",
    新密碼: "新しいパスワード",
    请输入新密碼: "新しいパスワードを入力してください",
    请再次输入密碼: "パスワードを再入力してください",
    兩次密碼不一致: "パスワードが 2 回矛盾している",
    模擬綁定: "バインドのシミュレーション",
    綁定帳號: "アカウントをバインドする",
    執行比例: "約定率",
    解除綁定: "アンバインド",
    綁定啟動: "製本開始",
    金額: "量",
    退出: "辞める",
    萬: "万",
    億: "億",
    h最高價: "時間の最高値",
    h最低價: "時間最低価格",
    h成交量: "時間の取引高",
    h成交額: "時間の売上高",
    帳號不能為空: "アカウントを空にすることはできません",
    今日看點: "本日のハイライト",
    歷史紀錄: "史料",
    Fee: "一回の出金手数料",
}
// 广告弹窗
<template>
  <div class="ad" v-if="show">
    <!-- <div class="title">{{title}}</div> -->
    <div class="content" v-html="content"></div>
    <div class="close" @click="show = false">x</div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      content: "",
      show: false,
      title:""
    };
  },
  created() {
    this.getNewsContent();
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    getNewsContent() {
      axios
        .post(
          `${this.$BASE_URL}/server/api/public/?c=News&a=announcement&uid=${localStorage.user_add}&lang=${this.lang.locale}`
        )
        .then((res) => {
          if (res.data.code === 1) {
            if (res.data.return) {
              this.title = res.data.return.title;
              this.content = res.data.return.content;
              this.show = true;
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ad {
  position: fixed;
  top: 4rem;
  width: 80vw;
  max-width: 300px;
  min-height: 7rem;
  background: #1b1b25;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  padding: 5px;
  color: #fff;
  border: 1px solid #267482;
  .title{
    text-align: center;
    font-size: 14px;
    padding: 5px;
  }
  .content{
    padding: 0 5px;
  }
  img{
    max-width: 100%;
    height: auto;
  }
}

.close {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -70px;
  border-radius: 50%;
  background: #21c7d4;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  font-size: 25px;
}
</style>
<style>

.ad img{
  max-width: 100%;
  height: auto;
}


</style>
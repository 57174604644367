import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/home/"
    },
    {
        path: '/home',
        meta:{
            noLogin:true,
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/today/index.vue'),
        name: 'home',
    },
    {
        path: '/today',
        name: 'today',
        noLogin:true,
        meta:{
            noLogin:true,
        },
        component: () => import(/* webpackChunkName: "dailiuser" */ '../views/today/index.vue')
    },
    {
        path: '/simulation',
        name: 'simulation',
        component: () => import(/* webpackChunkName: "dailiuser" */ '../views/simulation/index.vue')
    },
    {
        path: '/invitation',
        name: 'invitation',
        component: () => import(/* webpackChunkName: "invitation" */ '../views/invitation/invitation.vue')
    },
    {
        path: '*',
        name: 'page404',
        component: () => import(/* webpackChunkName: "page404" */ '../views/404/404.vue')
    },
    
]

const router = new VueRouter({
    mode: "history",
    base: '/',
    routes
})

export default router;
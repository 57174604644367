import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import price from '@/store/modules/price'
import jurisdiction from '@/store/modules/jurisdiction'
import msg from'@/store/modules/message'
import lang from '@/store/modules/lang'
import wallet from '@/store/modules/wallet'

Vue.use(Vuex)
export default new Vuex.Store({
    plugins:[createPersistedState()],
    getters,
    modules:{
        price,jurisdiction,msg,lang,wallet
    }
})
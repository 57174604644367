const grade = {
    state: {
        jurisdiction: 0
    },
    mutations: {
        SET_GRADE: (state, jurisdiction) => {
            state.jurisdiction = jurisdiction
        }
    }
}
export default grade;
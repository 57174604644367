export default {
    rule: "rule",
    investment: "Total Investment",
    mine: "My",
    Lotteryrecord: "Settlement Record",
    Gamerecord: "Game Record",
    Trend: "trend",
    bravetroops: "Bitcoin",
    tiger: "Litecoin",
    leopard: "Ethereum",
    Numberofperiods: "Serial Number",
    Lotterytime: "Settlement Time",
    Lotteryresults: "Settlement Result",
    Bet: "bet",
    Win: "winning",
    countdown: "countdown",
    previouspage: "Previous page",
    nextpage: "Next page",
    Lastroundresults:	"Last round result",
    details: "Details",
    Leopardodds: "Buy Ethereum",
    tigerodds: "Buy Litecoin",
    Nodata: "No Data",
    Relevantrules: "Relevant rules",
    return: "return",
    Turntablegame: "Contract Transaction",
    PetMarket: "Pet Market",
    foundation: "Foundation",
    Usercenter: "User Center",
    ServiceOnline: "Online Service",
    help: "Help Description",
    announcement: "Announce",
    Language: "Language",
    Whitepaper: "White Paper",
    zhiyarule: "Distribute rewards according to the pledge ratio",
    Clickpledge: "Click Pledge",
    Pledgerecord: "Pledge Record",
    Rewardrecord: "Reward Record",
    Pledgetime: "Pledge Time",
    Pledgegoldcoins: "Pledge Gold Coins",
    example: "Example",
    tou: "cast",
    jinbi: "Gold Coins",
    huode: "Settlement available",
    goldnumbders: "Gold Coins Counts",
    entergold: "Please enter the number of gold coins",
    usegold: "Available gold coins",
    winninggold: "Expected to get gold coins",
    Confirm: "Confirm",
    Cancel: "Cancel",
    information: "Information",
    successful: "Purchase successful.",
    Pledgeofgold: "Gold coin pledge",
    Pledgesucceeded: "Pledge successful.",
    Notice: "Announcement",
    development: "This feature is under development.",
    rechargetitle: "Gold coin recharge",
    rechargemsg: "Recharge gold coins to play roulette, draw, and pledge",
    Selectcurrency: "Select currency",
    Rechargequantity: "Recharge quantity",
    Pleaseentertherechargequantity: "Please enter the amount of recharge",
    Getgold: "Get gold coins" ,
    Confirmrecharge: "Confirm recharge",
    Rechargerecord: "Recharge record",
    successfulrecharge: "Congratulations on your successful recharge",
    Rechargeused: "Recharge record",
    Accountbalance: "Account Balance",
    Withdraw: "Withdraw",
    rotation: "Interchange",
    team: "My Team",
    management: "Agent management",
    Invite: "Invite now",
    game: "game",
    Pledge: "Pledge",
    Inviteconnection: "Invite connection",
    Receiveraddress: "Receiver address",
    entertherecipientaddress: "Please enter the recipient address",
    transferquantity: 	"Interchange quantity",
    Insufficientavailablegoldcoins: "Not enough gold coins available",
    enterthequantityofmutualtransfer: "Please enter the quantity for mutual transfer",
    Reciprocalrecord: 	"Intertransfer records",
    successfullytransferredout: "You successfully transferred out",
    Withdrawalquantity: "Withdrawal amount",
    entertheamountofwithdrawal: "Please enter the amount of coins to withdraw",
    Withdrawalrecord: 	"Withdrawal Record",
    Checkinginprogress: "Under review, will arrive later",
    Adjustpersonnel: 	"Adjustment staff",
    all: 	"All",
    entertheodds: "Please enter the opponent odds",
    odds: 	"Odds cash back",
    Enteraddresssearch: "Enter address to search",
    Languageswitching:		"Language",
    Chinese: "Traditional Chinese",
    English: "English",
    Japanese: "Japanese",
    Korean: "Korean",
    Publishproperties: "Post content",
    entercontentpublishing: "Please enter content to publish",
    Publishnow: "Publish immediately",
    Informationreleasedsuccessfully: "Information released successfully.",
    gamegold: "Game amount",
    Gameresults: "Game results",
    Wintheprize: "Winning",
    Gametime: "Game Time",
    Win_msg: "Congratulations",
    lose_msg: "It is a pity that you missed the bonus",
    Totalassets: "Total assets",
    Totalprofitandloss: "Total profit and loss",
    Todayprofitandloss: "Today profit and loss",
    Recharge: "Recharge",
    Withdrawal: "Withdraw",
    luckdraw: "lottery",
    type: 	"type",
    time: 	"Time",
    Status: "state",
    Funddetails: "Details",
    gameresults: "Game results",
    gamenum: "Game amount",
    gametype: "Game state",
    myodds: "My odds",
    Copy: 	"Copy",
    getWin: "congratulations",
    getLose: "It is a pity that you missed the bonus",
    changesuccess: "Successfully modified",
    TotalCommission: "total commission",
    GameCommission: "Game commission",
    PledgeCommission: "Staking commission",
    Commission: "Mercenary",
    wait: 	"Please try again later",
    Total: 	"Total pledge",
    teamZhiya: "Team",
    Exchange: "Exchange points for more rewards",
    again: 	"Smoke again",
    Thanks: "Thank you",
    LotteryRecord: "Lottery records",
    DrawOnce: "Frequency",
    ClickLuckyDraw: "Click draw",
    noDraw: "You have no draws",
    sings: 	"Please refresh the page and re-sign",
    feedback: "Feedback",
    Submitfeedback: "Submit feedback",
    noMOre: "No more",
    submit: "Submit",
    PleaseProvideFeedback: "Please enter your feedback",
    loading: "loading",
    ReleaseToRefresh: "release refresh",
    PullToRefresh: "Pull down to refresh",
    submitSuccess: "Submitted successfully",
    content: "content",
    reply: "reply",
    反饋內容: "Feedback content",
    反饋類型: "Feedback type",
    上傳圖片: "upload image",
    maxUploadSize: "File size cannot exceed 1M",
    反饋紀錄: "Feedback record",
    反饋內容: "Feedback content",
    未回覆: "no reply",
    已回覆: "replied",
    暫無記錄: "No records",
    合約交易: "Contract trading",
    當前擁有寵物: "Currently pet",
    兑换: "exchange",
    收益記錄: "Earnings record",
    購買記錄: "Purchase History",
    收益: "income",
    幣種: "Currency",
    時間: "Time",
    消耗: "consume",
    最大: "Max",
    获得: "get",
    预估值: "Estimated",
    金幣: "Coin",
    兌換為金幣: "Exchange coins",
    兌換紀錄: "Redemption history",
    返還紀錄: "Return record",
    名稱: "Name",
    數量: "Quantity",
    基金總額: "Total fund",
    已返基金: "Funds returned",
    待返基金: "Funds returnning",
    基金會說明: "Foundation description",
    我的直推: "Direct recommendation",
    邀請好友: "Invite Friends",
    複製鏈接: "copy Link",
    團隊人數: "Team size",
    直推人數: "Direct referrals counts",
    我的傭金: "My commission",
    傭金明細: "Commission details",
    幣種類型: "Currency type",
    帳戶明細: "Account details",
    Contract: "Contract",
    Pets: "Pet",
    复制成功: "Copied successfully",
    邀請碼: "Invitation code",
    委託持倉: "Entrusted position",
    歷史訂單: "Historical orders",
    合約簡介: "Contract introduction",
    簡介: "Introduction",
    排名: "Ranking",
    發行時間: "Publish time",
    發行總量: "Total issuance",
    流通總量: "Total circulation",
    總市值: "Total market capitalization",
    流通市值: "Circulation market",
    歷史最高價: "Highest",
    歷史最低價: "Lowest",
    買跌: "Buy Down",
    買漲: "Buy Up",
    實時價格: "Real-time price",
    委託類型: "Delegation type",
    委託時間: "Delegation time",
    委託手數: "Entrusted lot size",
    請輸入手數: "Please enter the lot size",
    手: "hand",
    分鐘: "minute",
    確定下單: "Confirm order",
    警告: "warn",
    警告提示: "You have not registered as a user, you need to register by invitation, please contact the person in charge",
    單價: "unit price",
    預計收益: "Estimated earnings",
    寵物詳情: "Pet details",
    寵物介紹: "Pet introduction",
    購買: "Buy",
    買入數量: "Buy quantity",
    合計: "total",
    總額: "lump sum",
    合約名稱: "Contract name",
    手數: "Lot size",
    槓桿: "lever",
    盈虧: "profit and loss",
    开放日返还: "Open Day Return",
    常规返还: "Regular return",
    一鍵平倉: "Close Position ALL",
    平倉: "Close Position",
    持倉類型: "Position type",
    方向: "direction",
    手續費: "handling fee",
    開倉時間: "Opening time",
    開倉價格: "Opening price",
    當前價格: "Current price",
    委託手數: "Entrusted lot size",
    預計盈虧: "Estimated",
    買漲: "Buy up",
    平倉時間: "Close time",
    操作類型: "Operation type",
    系统平仓: "System closing",
    用户平仓: "User close position",
    待定: "To be determined",
    結算價格: "Settlement Price",
    餘額: "Balance",
    溫馨提示: "Kind tips",
    兌換比例: "Exchange ratio",
    本期開放日為: "The opening day of this issue is",
    請選擇反饋類型: "Please select feedback type",
    請輸入反饋內容: "Please enter feedback content",
    兌換金額: "Exchange amount",
    積分數量: "Amount of points",
    錢包選擇: "Wallet Options",
    連接錢包: "Connecting Wallets",
    止損: "Loss",
    止盈: "Full",
    设置: "Settings",
    登陸: "Login",
    登陸成功: "Landing successful",
    地址: "Address",
    錢包地址: "Wallet Address",
    密碼: "Password",
    修改密碼: "Change password",
    舊密碼: "Old Password",
    新密碼: "New Password",
    请输入新密碼: "Please enter a new password",
    请再次输入密碼: "Please enter your password again",
    兩次密碼不一致: "The password is inconsistent twice",
    模擬綁定: "Simulate bindings",
    綁定帳號: "Bind your account",
    執行比例: "Execution ratio",
    解除綁定: "Unbind",
    綁定啟動: "Binding starts",
    金額: "Amount",
    退出: "Quit",
    萬: "M",
    億: "B",
    h最高價: "h High",
    h最低價: "h Low",
    h成交量: "h Volume",
    h成交額: "h Amount",
    帳號不能為空: "Account cannot be empty",
    今日看點: "Today's highlights",
    歷史紀錄: "Historical records",
    Fee: "Single withdrawal fee"
}
<template>
  <div class="main-top">
    <router-link to="/home"
      ><div class="top-logo">
        <img src="../assets/img/logo.png" alt="" /></div
    ></router-link>
    <!-- <div class="null"></div> -->
    <div @click="to">
      <div class="center">
        <div class="img1">
          {{$t("lang.餘額")}}:
        </div>
        <div class="num">{{ money | numberToCurrency}}</div>
        <div class="img2">
          <img src="../assets/img/zengjia@3x.png" alt="" />
        </div>
      </div>
    </div>
    <div class="wallte" v-show="!showadd" @click="getWattle()">{{ $t("lang.連接錢包") }}</div>
    <div class="wallte" v-show="showadd" @click="getWattle()">
      {{ user_add }}
    </div>
    <div @click="changeType" class="menu">
      <img src="../assets/index_logo/caidan@3x.png" style="width: 22px;height: 22px;" alt="" />
      <div v-show="this.type === true" class="menu-cont">
        <ul>
          <li @click="change(1)" :class="this.color === 1 ? 'changed' : ''">
            <router-link class="link" to="/home">
              <img
                v-show="this.color !== 3"
                src="../assets/img/home_jijin.png"
                alt=""
              />
              <img
                v-show="this.color === 3"
                src="../assets/img/home_jijin.png"
                alt=""
              />
              <div>{{ $t("lang.Turntablegame") }}</div>
            </router-link>
          </li>
          <li @click="change(2)" :class="this.color === 2 ? 'changed' : ''">
            <router-link class="link" to="/petMarket">
              <img
                v-show="this.color !== 2"
                src="../assets/img/home_chongwu.png"
                alt=""
              />
              <img
                v-show="this.color === 2"
                src="../assets/img/home_chongwu.png"
                alt=""
              />
              <div>{{ $t("lang.PetMarket") }}</div>
            </router-link>
          </li>
          <li @click="change(3)" :class="this.color === 3 ? 'changed' : ''">
            <router-link class="link" to="/foundation">
              <img
                v-show="this.color !== 1"
                src="../assets/img/home_heyue.png"
                alt=""
              />
              <img
                v-show="this.color === 1"
                src="../assets/img/home_heyue.png"
                alt=""
              />
              
              <div>{{ $t("lang.foundation") }}</div>
            </router-link>
          </li>
          <li
            v-if="this.grade == 1"
            @click="change(4)"
            :class="this.color === 4 ? 'changed' : ''"
          >
            <router-link class="link" to="/invitation">
              <img
                v-show="this.color !== 4"
                src="../assets/img/yaoqing@2x.png"
                alt=""
              />
              <img
                v-show="this.color === 4"
                src="../assets/img/yaoqingdian@2x.png"
                alt=""
              />
              <div>{{ $t("lang.announcement") }}</div>
            </router-link>
          </li>
          <li @click="change(5)" :class="this.color === 5 ? 'changed' : ''">
            <router-link class="link" to="/user/gameteam">
              <img
                v-show="this.color !== 5"
                src="../assets/img/home_yonghu.png"
                alt=""
              />
              <img
                v-show="this.color === 5"
                src="../assets/img/home_yonghu.png"
                alt=""
              />
              <div>{{ $t("lang.Usercenter") }}</div>
            </router-link>
          </li>
          <li @click="
              change(6);
              toPhone();
            " :class="this.color === 6 ? 'changed' : ''">
              <img
                v-show="this.color !== 6"
                src="../assets/img/honme_kefu.png"
                alt=""
              />
              <img
                v-show="this.color === 6"
                src="../assets/img/honme_kefu.png"
                alt=""
              />
              <div>{{ $t("lang.ServiceOnline") }}</div>
          </li>
          <li
            @click="
              change(7);
              feedback();
            "
            :class="this.color === 7 ? 'changed' : ''"
          >
            <img
              v-show="this.color !== 7"
              src="../assets/img/home_yijian.png"
              alt=""
            />
            <img
              v-show="this.color === 7"
              src="../assets/img/home_yijian.png"
              alt=""
            />
            <div>{{ $t("lang.feedback") }}</div>
          </li>
          <li
            @click="
              showlanguage();
              change(8);
            "
            :class="this.color === 8 ? 'changed' : ''"
          >
            <img
              v-show="this.color !== 8"
              src="../assets/img/home_yuyan .png"
              alt=""
            />
            <img
              v-show="this.color === 8"
              src="../assets/img/home_yuyan .png"
              alt=""
            />
            <div>{{ $t("lang.Language") }}</div>
          </li>
          <li
            @click="
              change(9);
              toPapger();
            "
            :class="this.color === 9 ? 'changed' : ''"
          >
            <img
              v-show="this.color !== 9"
              src="../assets/img/home_baipishu.png"
              alt=""
            />
            <img
              v-show="this.color === 9"
              src="../assets/img/home_baipishu.png"
              alt=""
            />
            <div>{{ $t("lang.Whitepaper") }}</div>
          </li>
          <li
            @click="
              change(10);
            "
            :class="this.color === 10 ? 'changed' : ''"
          >
            <router-link class="link" to="/help">
              <img
                v-show="this.color !== 10"
                src="../assets/img/home_bangzhu .png"
                alt=""
              />
              <img
                v-show="this.color === 10"
                src="../assets/img/home_bangzhu .png"
                alt=""
              />
              <div>{{ $t("lang.help") }}</div>
            </router-link>
          </li>
          <!-- <li
            @click="
              change(11);
              toPhone();
            "
            :class="this.color === 11 ? 'changed' : ''"
          >
            <img
              v-show="this.color !== 11"
              src="../assets/index_logo/telegram@2x.png"
              alt=""
            />
            <img
              v-show="this.color === 11"
              src="../assets/index_logo/telegramdian@2x.png"
              alt=""
            />
            <div>Telegram</div>
          </li> -->
        </ul>
      </div>
    </div>
    <van-popup class="language" v-model="languageType">
      <div class="title">
        <div class="left"></div>
        <div class="center">{{ $t("lang.Languageswitching") }}</div>
        <div class="right" @click="closelanguage">
          <img src="../assets/index_logo/guanbi@2x.png" alt="" />
        </div>
      </div>
      <div class="cont">
        <div class="cont-list">
          <div class="left">
            <img src="../assets/index_logo/fanti@2x.png" alt="" />
            <div>{{ $t("lang.Chinese") }}</div>
          </div>
          <div class="right" @click="changeLanguage('zh')">
            <img
              v-show="gouxuan == 'zh'"
              src="../assets/index_logo/gouxuan@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="cont-list">
          <div class="left">
            <img src="../assets/index_logo/english@2x.png" alt="" />
            <div>{{ $t("lang.English") }}</div>
          </div>
          <div class="right" @click="changeLanguage('en')">
            <img
              v-show="gouxuan == 'en'"
              src="../assets/index_logo/gouxuan@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="cont-list">
          <div class="left">
            <img src="../assets/index_logo/riyu@2x.png" alt="" />
            <div>{{ $t("lang.Japanese") }}</div>
          </div>
          <div class="right" @click="changeLanguage('jp')">
            <img
              v-show="gouxuan == 'jp'"
              src="../assets/index_logo/gouxuan@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="cont-list">
          <div class="left">
            <img src="../assets/index_logo/hanguo@2x.png" alt="" />
            <div>{{ $t("lang.Korean") }}</div>
          </div>
          <div class="right" @click="changeLanguage('hg')">
            <img
              v-show="gouxuan == 'hg'"
              src="../assets/index_logo/gouxuan@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup class="language1" v-model="window">
      <div class="title">
        <div class="left"></div>
        <div class="center">{{ $t("lang.Notice") }}</div>
        <div class="right" @click="closeWindow">
          <img src="../assets/index_logo/guanbi@2x.png" alt="" />
        </div>
      </div>
      <div class="message">
        <div class="text">
          <span>{{ $t("lang.development") }}</span>
        </div>
      </div>
      <div class="bottom_btn">
        <div class="button" @click="closeWindow">{{ $t("lang.Confirm") }}</div>
      </div>
    </van-popup>
    <van-popup class="language" v-model="wallet">
      <div class="title">
        <div class="left"></div>
        <div class="center">{{ $t("lang.錢包選擇") }}</div>
        <div class="right" @click="wallet=false">
          <img src="../assets/index_logo/guanbi@2x.png" alt="" />
        </div>
      </div>
      <div class="cont">
        <div class="cont-list">
          <div class="left">
            <div>BSC</div>
          </div>
          <div class="right" @click="changeWallet('bsc')">
            <img
              v-show="selected == 'bsc'"
              src="../assets/index_logo/gouxuan@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="cont-list">
          <div class="left">
            <div>TRON</div>
          </div>
          <div class="right" @click="changeWallet('tron')">
            <img
              v-show="selected == 'tron'"
              src="../assets/index_logo/gouxuan@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="bottom_btn">
          <div class="button" @click="handleWallet">
            {{ $t("lang.Confirm") }}
          </div>
        </div>
      </div>
    </van-popup>
    <Ad v-if="isAdshow" />
  </div>
</template>
<script>
import Ad from "./ad-popup.vue";

import { $userCount } from "@/api/index";
import { ethers } from "ethers";

export default {
  components: {
    Ad,
  },
  name: "MyTop",
  data() {
    return {
      type: false,
      languageType: false,
      isAdshow: false,
      show: false,
      color: "",
      user_add: "",
      showadd: false,
      grade: "",
      window: false,
      lang: "",
      gouxuan: "",
      url: `${this.$BASE_URL}/h5?id=`,
      papgerlang: "",
      wallet: false,
      tronWeb: null,
      selected: "bsc",
    };
  },
  mounted() {
    this.isAdshow = true;
    this.getLang();
    this.url = this.url + this.$store.getters.locale;
    this.gouxuan = this.$store.getters.locale;
    this.showId();
    $userCount({
      uid: localStorage.user_add,
    }).then((res) => {
      this.grade = res.admin;
      
    });
    setTimeout(() => {
      this.getWallet();
    }, 500);

  },
  updated() {
  },
  computed:{
    money() {
      return this.$store.state.price.price;
    },
  },
  watch: {
    // selected(value) {
    // this.$store.commit("SET_WALLET", value);
    // },
    // wallet(newV) {
    //   if (newV&&localStorage.getItem("user_add").substring(0, 1) == "T") {
    //    this.selected='tron'
    //   }else{
    //    this.selected='bsc'
    //   }
    // },
  },
  methods: {
    update(){
      $userCount({
        uid: localStorage.user_add,
      }).then((res) => {
        this.grade = res.admin;
      });
    },
    feedback() {
      // this.$router.push({
      //   path: "/feed-back",
      // });
    },
    getWallet() {
      if (window.tronWeb && window.ethereum) return (this.wallet = true);
      if (window.ethereum) {
        this.selected = "bsc";
        return this.ethereumWeb3();
      }
      if (window.tronWeb) {
        this.selected = "tron";
        return this.tronWeb3();
      }
    },
    getLang() {
      if (this.$store.getters.locale == "zh") {
        this.papgerlang = 1;
      }
      if (this.$store.getters.locale == "en") {
        this.papgerlang = 2;
      }
      if (this.$store.getters.locale == "jp") {
        this.papgerlang = 3;
      }
      if (this.$store.getters.locale == "hg") {
        this.papgerlang = 4;
      }
    },
    to() {
      location.href =
        this.url +
        "&web=" +
        (localStorage.getItem("user_add").substring(0, 1) == "T"
          ? "tron"
          : "bsc")+'#';
      // this.url+this.$store.getters.locale
      // Window.Location.href(this.url)
    },
    toPhone() {
      window.open("https://0.plus/HasDoge111", "", "");
    },
    toPapger() {
      if (this.papgerlang == 1) {
        window.open(`${this.$BASE_URL}/LitepaperCHV328.pdf`, "", "");
      }
      if (
        this.papgerlang == 2 ||
        this.papgerlang == 3 ||
        this.papgerlang == 4
      ) {
        window.open(`${this.$BASE_URL}/LitepaperENV328.pdf`, "", "");
      }
    },
    //切换语言
    changeLanguage(lang) {
      // this.$i18n.locale="zh";
      this.$store.commit("SET_LANG", lang);
      this.gouxuan = this.$store.getters.locale;
      this.url = this.url + this.$store.getters.locale;
      this.$router.go(0);
    },
    changeWallet(value) {
      this.selected = value;
    },
    handleWallet() {
      // 签名
      this.selected == "bsc" ? this.ethereumWeb3() : this.tronWeb3();
      this.wallet = false;
      this.showId();
      setTimeout(() => {
        $userCount({
          uid: localStorage.user_add,
        }).then((res) => {
          this.grade = res.admin;
        });
      }, 500);
    },
    showWindow() {
      this.window = true;
    },
    closeWindow() {
      this.window = false;
      this.color = "";
    },
    //用户信息
    showId() {
      if (localStorage.user_add) {
        let a = localStorage.user_add.substr(0, 4);
        let b = localStorage.user_add.substr(localStorage.user_add.length - 4);
        this.user_add = a + "..." + b;
        this.showadd = true;
      } else {
        this.showadd = false;
      }
    },
    getWattle() {
      this.wallet = true;
    },
    async ethereumWeb3() {

      // 请求用户授权
      await window.ethereum.enable();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      signer.getAddress().then((res) => {
        if (res != localStorage.getItem('user_add')||!localStorage.getItem('user_add')) {
          this.$EventBus.$emit("selected", "bsc");
          this.$store.commit("SET_WALLET", "bsc");
        }
        localStorage.setItem("user_add", res);
        let a = localStorage.user_add.substr(0, 4);
        let b = localStorage.user_add.substr(localStorage.user_add.length - 4);
        this.user_add = a + "..." + b;
        // this.user_add = res
        $userCount({
          uid: localStorage.user_add,
        }).then((res) => {
          this.grade = res.admin;
        });
      });
    },
    tronWeb3() {
      if (window.tronWeb) {
        this.tronWeb = window.tronWeb;
        // this.tronWeb.createAccount();
        if ( this.tronWeb.defaultAddress.base58 != localStorage.getItem('user_add')||!localStorage.getItem('user_add')) {
          this.$EventBus.$emit("selected", "tron");
          this.$store.commit("SET_WALLET", "tron");
        }
        this.user_add = this.tronWeb.defaultAddress.base58;
        localStorage.setItem("user_add", this.user_add);
        this.showId();
        $userCount({
          uid: localStorage.user_add,
        }).then((res) => {
          this.grade = res.admin;
        });
      }
    },
    //菜单栏
    changeType() {
      this.type = !this.type;
    },
    //切换语言弹窗
    showlanguage() {
      this.languageType = true;
    },
    closelanguage() {
      this.languageType = false;
    },
    //菜单是否选中
    change(color) {
      this.color = color;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-top {
  background-color: #242636;
  width: 100%;
  height: 1.375rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #516078;
  .top-logo {
    padding-left: 12px;
    width: 0.9rem;
    height: 0.9rem;
    img {
      width: 100%;
    }
  }
  .null {
    width: 2.9688rem;
  }
  .center {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    .num {
      font-size: 15px;
      color: #fec360;
    }
    .img1 {
      color: #fec360;
      font-size: 15px;
      white-space: nowrap;
    }
    .img2 {
      width: 0.375rem;
      height: 0.375rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .wallte {
    width: 2.5rem;
    height: 0.75rem;
    box-sizing: border-box;
    // border: 1px solid #21c7d4;
    font-size: 15px;
    color: #20C3FF;
    text-align: center;
    border-radius: 0.25rem;
    line-height: 0.75rem;
    margin: 0 0.1875rem 0 0.1875rem;
  }
  .menu {
    position: relative;
    // height: 0.75rem;
    // display: flex;
    // align-items: center;
    img {
      padding-right: 0.30rem;
      width: 0.75rem;
      height: 0.75rem;
    }
    .menu-cont {
      position: absolute;
      margin-top: 10px;
      width: 3.4656rem;
      height: 9.9375rem;
      // background: url("../assets/index_logo/tan_bj@2x.png") no-repeat;
      // background-size: 100%;
      // border-radius: 0.25rem;
      left: -3rem;
      z-index: 999;
      padding: 0.375rem 0 0 0.375rem;
      background: #131525;
      border-radius: 5px;
      a {
        color: #a99fc0;
        display: inline-block;
      }
      ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        li {
          flex: 1 1 auto;
          width: 100%;
          display: flex;
          height: 0.9375rem;
          font-size: 15px;
          color: #a99fc0;
          .link {
            display: flex;
          }
          img {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
        .changed {
          color: #21c7d4;
          .link {
            color: #21c7d4;
          }
        }
      }
    }
  }
  .language {
    width: 8.9688rem;
    height: 8.2969rem;
    border-radius: 0.25rem;
    border: none;
    background-color: #222832;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #242636;
      height: 1.375rem;
      .left {
        width: 0.6875rem;
      }
      .center {
        width: 5.25rem;
        text-align: center;
        color: #d8d2e4;
        font-size: 18px;
        display: flex;
        justify-content: center;
      }
      .right {
        width: 0.6875rem;
        img {
          width: 0.4063rem;
          height: 0.4375rem;
        }
      }
    }
    .cont {
      padding: 0 0.25rem;
      .cont-list {
        margin-top: 0.5rem;
        background-color: #1c1f26;
        border-radius: 0.125rem;
        width: 100%;
        font-size: 15px;
        color: #d8d2e4;
        height: 1.125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          margin-left: 0.3125rem;
          display: flex;
          align-items: center;
          img {
            margin-right: 0.3125rem;
            width: 0.5rem;
            height: 0.375rem;
          }
        }
        .right {
          margin-right: 0.3125rem;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #282d3a;
          img {
            width: 0.5rem;
            height: 0.5rem;
          }
        }
      }
    }
    .bottom_btn {
      margin: 1rem 0.25rem 0 0.25rem;
      border-radius: 0.25rem;
      .button {
        width: 100%;
        height: 1.375rem;
        border-radius: 0.1563rem;
        background: #21c7d4;
        color: #ffffff;
        text-align: center;
        line-height: 1.375rem;
        font-size: 15px;
      }
    }
  }
  .language1 {
    position: fixed;
    z-index: 11111;
    width: 8.9688rem;
    height: 6.2969rem;
    border-radius: 0.25rem;
    border: none;
    background-color: #222832;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #242636;
      height: 1.375rem;
      .left {
        width: 0.6875rem;
      }
      .center {
        width: 1rem;
        text-align: center;
        color: #d8d2e4;
        font-size: 18px;
      }
      .right {
        width: 0.6875rem;
        img {
          width: 0.4063rem;
          height: 0.4375rem;
        }
      }
    }
    .message {
      padding: 0 0.25rem;
      text-align: center;
      margin-top: 1.25rem;
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
      .text {
        margin-top: 0.625rem;
        font-size: 15px;
        color: #d8d2e4;
        span:nth-of-type(2) {
          color: #21c7d4;
        }
      }
    }
    .bottom_btn {
      margin: 1rem 0.25rem 0 0.25rem;
      border-radius: 0.25rem;
      .button {
        width: 100%;
        height: 1.375rem;
        border-radius: 0.1563rem;
        background: #21c7d4;
        color: #ffffff;
        text-align: center;
        line-height: 1.375rem;
        font-size: 15px;
      }
    }
  }
}
</style>
const wallet = {
    state: {
        selected: ''
    },
    mutations: {
        SET_WALLET: (state, value) => {
            state.selected = value
        }
    }
}
export default wallet;
import request from '@/utils/request'
import store from '@/store'
 function comm(data){
    let str=""
    for (const iterator in data) {
        // console.log(data[iterator] ,55555)
        str+='&'+iterator+'='+data[iterator]
    }
    return str
 }
 
export const  $createCount=(data)=>{
    return request.post("?c=Team&a=userCreate"+comm(data))//创建用户
}


export const $userCount= async(data)=>{//余额
    const newData = {
        uid: localStorage.user_add,
        ...data,
    }
    try {
        const res = await request.post("?c=Property&a=myPrice"+comm(newData))//可用余额
        if(res.data && res.data.code === 1 && res.data.return){
            store.commit("SET_PRICE", res.data.return.price);
            store.commit("SET_GRADE", res.data.return.admin);
            return res.data.return
        }else{
            return {}
        }
    } catch (error) {
        return {}
    }
}
export const $getInfo=(data)=>{
    return request.post("?c=User&a=getInfo"+comm(data))//
}




// ----------------------------------------------------------------------------------------
const getLang =()=>{
    return {
        lang: store.getters.locale || '',
        uid: localStorage.user_add,//'0x118fDA55Add86189f137Fb3F45055b2BB08c0C82' ||  '0x51E2878e68310Fa8713bf494C2683DaC98A74097',// 
    }
}
const goToken =()=>{
    return {
        token: localStorage.token,
    }
}
export const goApi={


    coinList(data){//K线币种列表
        return request.post("?c=Kline&a=coinList"+comm({...data,...getLang()}))
    },

    yesterdayClose(data){//昨日结束价
        return request.post("?c=Kline&a=yesterdayClose"+comm({...data,...getLang()}))
    },


    getInfo(data){//用户信息（邀请码）
        return request.post("?c=User&a=getInfo"+comm({...data,...getLang()}))
    },
    




    Article(data){//获取各种说明书
        return request.post("?c=Article&a=indexList"+comm({...data,...getLang()}))
    },

    upload(formData){//上传图片接口（如：反馈图片）
        return request.post("?c=Upload&a=Upload",formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    
    indexList(data){//今日看点
        return request.post("?c=Hotnews&a=indexList"+comm({...data,...getLang()}))
    },
    followCfg(data){//跟单账号配置列表
        return request.post("?c=Hot&a=followCfg"+comm({...data,...getLang(),...goToken()}))
    },



    login(data){//登录接口
        return request.post("?c=Hot&a=login"+comm({...data,...getLang()}))
    },
    repwd(data){//跟单账号配置列表
        return request.post("?c=Hot&a=repwd"+comm({...data,...getLang(),...goToken()}))
    },
    userInfo(data){//用户绑定情况
        return request.post("?c=Hot&a=UserInfo"+comm({...data,...getLang(),...goToken()}))
    },
    follow(data){//跟单绑定/解绑账号
        return request.post("?c=Hot&a=follow"+comm({...data,...getLang(),...goToken()}))
    },
    klineDetail(data){//24小时线详情
        return request.post("?c=Hot&a=klineDetail"+comm({...data,...getLang()}))
    },
}
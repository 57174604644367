const message = {
    state: {
        msg: 0
    },
    mutations: {
        SET_MSG: (state, msg) => {
            state.msg = msg
        }
    }
}
export default message;